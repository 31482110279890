var $ = require("jquery");
var breakpoint = require('./app-breakpoints');
require('./app-accordion');
var slick = require("slick");
var _ = require('underscore');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");

var sticky = $('.c-navigation').offset().top;

require('./app-map');

$(function() {
	$('.carousel').slick({
		autoplay: true,
		responsive: false,
		fade: true,
		dots: false,
		autoplaySpeed: 8500,
		arrows: false
	});

	$('.carousel-element').slick({
		autoplay: true,
		responsive: [
		{
		 breakpoint: 767,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
		}],
		//fade: true,
		dots: true,
		slidesToShow: 2,
 		slidesToScroll: 2,
		arrows: false
	});


	$(".post .item .item-media").click(function() {
		if( $(this).find("a").attr("href") ) {
	 		 window.location = $(this).find("a").attr("href");
		}
	  return false;
	});

	$(window).scroll(function() {
		console.log(_gridLabel);

		if(_gridLabel != "smartphone") {
			if ($(this).scrollTop() > sticky){
		   	 $('.c-navigation').addClass("sticky");
			  } else {
			    $('.c-navigation').removeClass("sticky");
			  }
		}
	});

	$(document).on('click', '.nav-trigger', function(e) {
		$(this).toggleClass('is-active');
		$('#menu-mobile').slideToggle();
		return false;
	});

	$(document).on("click", ".new-window", function () {

		var w = 500;
		var h = 500;

		var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
		var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

		var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
   		var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

   		var title = $(this).attr('data-title');
   		var url = $(this).attr('href');

		var left = ((width / 2) - (w / 2)) + dualScreenLeft;
		var top = ((height / 2) - (h / 2)) + dualScreenTop;
		var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

		if (window.focus) {
	       newWindow.focus();
	    }

   		return false;
    });

    $(window).resize(function(){
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');

		if(_gridLabel == "smartphone") {
			$('.c-navigation').removeClass("sticky");
		}

	}).resize();


	
	$(".c-alimentaire .list a").click(function () {
		$(".c-alimentaire .list li").removeClass("active"),
		$(this).parent().addClass("active"),
		$(".c-alimentaire .contentBox").hide(),
		$(".c-alimentaire .contentBox[data-id=" + $(this).attr("data-id") + "]").fadeIn();
	});
	$(".c-alimentaire .list li:eq(0) a").trigger("click");
	$(".carousel").slick({ autoplay: !0, responsive: !1, fade: !0, dots: !1, autoplaySpeed: 8500, arrows: !1 });
	$(".carousel-element").slick({ autoplay: !0, responsive: [{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 } }], dots: !0, slidesToShow: 2, slidesToScroll: 2, arrows: !1 });
	$(".post .item .item-media").click(function () {
		return $(this).find("a").attr("href") && (window.location = $(this).find("a").attr("href")), !1;
	});
	$(window).scroll(function () {
		"smartphone" != a && ($(this).scrollTop() > l ? $(".c-navigation").addClass("sticky") : $(".c-navigation").removeClass("sticky"));
	});
	$(document).on("click", ".nav-trigger", function (e) {
		return $(this).toggleClass("is-active"), $("#menu-mobile").slideToggle(), !1;
	});
	$(document).on("click", ".new-window", function () {
		var e = 500,
			t = 500,
			n = void 0 != window.screenLeft ? window.screenLeft : screen.left,
			o = void 0 != window.screenTop ? window.screenTop : screen.top,
			r = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width,
			s = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height,
			a = $(this).attr("data-title"),
			l = $(this).attr("href"),
			c = r / 2 - e / 2 + n,
			u = s / 2 - t / 2 + o,
			d = window.open(l, a, "scrollbars=yes, width=" + e + ", height=" + t + ", top=" + u + ", left=" + c);
		return window.focus && d.focus(), !1;
	});
	$(window).resize(function () {
			(r = o().match(/(\d+)/g)), (s = i(window).width()), (_margin = (s - r) / 2), (a = o().replace(/\d+/g, "").replace(" ", "")), "smartphone" == a && i(".c-navigation").removeClass("sticky");
	}).resize();
		
	/*
	function PopupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
}
	 */
});

/*var $ = require("jquery");
var stick_in_parent = require("sticky-kit");
var slick = require("slick");
var breakpoint = require('./app-breakpoints');
var sameheight = require('./app-sameheight');
var TweenMax = require('TweenMax');
var _ = require('underscore');

var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var current = 0;
var next = 0;
var prev = 0;
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");
var debouncedLayout = _.debounce(_resize, 300);

var slickVar = {
	centerMode: true,
	autoplay: ((_gridSize > 768) ? true : false),
	variableWidth: true,
	slidesToShow: ((_gridSize > 768) ? 3 : 1),
	infinite: false,
	initialSlide: 1,
	centerPadding: '0',
	draggable: false
};

$(function() {
	_gridSize = breakpoint().match(/(\d+)/g);
	_winSize = $(window).width();
	var _margin = (_winSize - _gridSize) / 2;
	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");

	$('.fb-share').click(function(e) {
		e.preventDefault();
		window.open($(this).attr('href'), 'fbShareWindow',
			'height=450, width=550, top=' + ($(window).height() / 2 - 275) +
			', left=' + ($(window).width() / 2 - 225) +
			', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
		return false;
	});

console.log(_gridLabel);

	if (_gridLabel !== "smartphone" && _gridLabel !== "tablet" ) {
		$("#stick-in-parent").stick_in_parent({
			parent: '.source-_product_cat',
			sticky_class: 'is-stuck',
		});
	}

	$(window).resize(debouncedLayout);
	_resize();

	$(document).on('click', '.nav-trigger', function(e) {

		$(this).toggleClass('is-active');
		$('#menu-mobile').slideToggle();

		return false;
	});

	$(document).on('mouseenter', '.menu-item', function() {
		if (_gridSize > 768) {
			var height = $('.sub-menu ul', this).outerHeight(true);

			if (height) {
				TweenMax.set(['.menu-depth-1', '.menu-depth-2'], {
					height: height
				});
			}
		}
	});

	$(document).on('mouseenter', '.menu-item-has-children', function() {
		if (_gridSize > 768) {
			var padding = $(this).offset().left;
			var grid = $('#menu-menu-primaire').outerWidth();

			$(this).children('div.menu-depth-1').css({
				paddingLeft: padding
			});

			$('.menu-item-depth-1').css({
				width: grid * 0.3
			});

			$('.menu-depth-2').css({
				left: grid * 0.3
			}).children('ul').css({
				width: grid * 0.7
			});
		}
	});


	$('.slider').slick({
		autoplay: true,
		fade: true,
		dots: true,
		arrows: false
	});

	$('.product-formats-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		centerMode: true,
		asNavFor: '.product-formats-nav',
	});

	$('.product-formats-nav').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		centerPadding: 0,
		asNavFor: '.product-formats-slider',
		focusOnSelect: true,
		variableWidth: true,
		useTransform: false,
	});

	$('.related-products-slider').slick({
		adaptiveHeight: true,
		dots: true,
	});

	$('.related-products-slider').on('setPosition', function(event, slick,
		direction) {
		_resize();
	});

});

var runSlick = function() {
	current = 0;
	next = 0;
	prev = 0;

	if ($('.carousel').find('.c-list-item-carousel').length >= 3) {
		var carousel = $('.carousel');
		if ($(carousel).hasClass('slick-slider')) carousel.slick('unslick');

		carousel.on('init', function(slick) {
			if (_gridSize > 768) {
				current = carousel.find('.slick-current');
				prev = current.prev();
				next = current.next();
				TweenMax.set([prev, next], {
					autoAlpha: 1,
					scale: 0.7
				});
				TweenMax.set(current, {
					scale: 1,
					autoAlpha: 1
				});
			}
		});

		carousel.slick(slickVar).on('beforeChange', function(event, slick,
			currentSlide, nextSlide) {
			if (_gridSize > 768) {

				if (Math.abs(nextSlide - currentSlide) == 1) {
					direction = (nextSlide - currentSlide > 0) ? "right" : "left";
				} else {
					direction = (nextSlide - currentSlide > 0) ? "left" : "right";
				}
				TweenMax.to(slick.$slides.eq(currentSlide), 0.1, {
					scale: 0.7,
					autoAlpha: 1
				});
				TweenMax.to(slick.$slides.eq(nextSlide), 0.1, {
					autoAlpha: 1,
					scale: 1
				});

				if (direction === "right") {
					//edge
					TweenMax.to(slick.$slides.eq(currentSlide - 1), 0.1, {
						autoAlpha: 0
					});
					TweenMax.to(slick.$slides.eq(nextSlide + 1), 0.1, {
						autoAlpha: 1,
						scale: 0.7
					});
				} else {
					TweenMax.to(slick.$slides.eq(currentSlide + 1), 0.1, {
						autoAlpha: 0
					});
					TweenMax.to(slick.$slides.eq(nextSlide - 1), 0.1, {
						autoAlpha: 1,
						scale: 0.7
					});
				}
			}
		});

	} else if ($('.c-section.has-source .c-listing .c-list-item').length >= 3) {

		var carousel = $('.c-section.has-source .c-listing__wrapper');
		if ($(carousel).hasClass('slick-slider')) carousel.slick('unslick');

		// if( $(carousel).find('.c-list-item-carousel').length > 3) {
		carousel.on('init', function(slick) {
			if (_gridSize > 768) {
				current = carousel.find('.slick-current');
				prev = current.prev();
				next = current.next();

				TweenMax.set([prev, next], {
					autoAlpha: 1
				});
				TweenMax.set(current, {
					autoAlpha: 1
				});
			}
		});

		carousel.slick(slickVar).on('beforeChange', function(event, slick,
			currentSlide, nextSlide) {
			if (_gridSize > 768) {
				if (Math.abs(nextSlide - currentSlide) == 1) {
					direction = (nextSlide - currentSlide > 0) ? "right" : "left";
				} else {
					direction = (nextSlide - currentSlide > 0) ? "left" : "right";
				}
				TweenMax.to(slick.$slides.eq(currentSlide), 0.1, {
					autoAlpha: 1
				});
				TweenMax.to(slick.$slides.eq(nextSlide), 0.1, {
					autoAlpha: 1
				});

				if (direction === "right") {
					//edge
					TweenMax.to(slick.$slides.eq(currentSlide - 1), 0.1, {
						autoAlpha: 0
					});
					TweenMax.to(slick.$slides.eq(nextSlide + 1), 0.1, {
						autoAlpha: 1
					});
				} else {
					TweenMax.to(slick.$slides.eq(currentSlide + 1), 0.1, {
						autoAlpha: 0
					});
					TweenMax.to(slick.$slides.eq(nextSlide - 1), 0.1, {
						autoAlpha: 1
					});
				}
			}
		});
	}
	// }
}

$(window).resize(function(){
	_gridSize = breakpoint().match(/(\d+)/g);
	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");
	_winSize = $(window).width();
	_margin = (_winSize - _gridSize) / 2;
	maxHeight = -1;

	if (_gridSize > 1200) {
		$('.c-section--header.has-thumbnail .c-section__wrapper').css({
			paddingLeft: _margin
		});
	}
})

function _resize() {
	console.log("resize once");
	_gridSize = breakpoint().match(/(\d+)/g);
	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");
	_winSize = $(window).width();
	_margin = (_winSize - _gridSize) / 2;
	maxHeight = -1;

	//Reset
	$('.c-section--header.has-thumbnail .c-section__wrapper').css('paddingLeft',
		'');

	$('.menu-depth-1, .menu-depth-2').css({
		minHeight: $('.menu-depth-1').find('ul').eq(0).outerHeight(true)
	});

	if (_gridSize > 1200) {
		$('.c-section--header.has-thumbnail .c-section__wrapper').css({
			paddingLeft: _margin
		});
	}

	if ($('.same-height').length && _gridLabel !== "smartphone") {
		$('.same-height').equalRowHeights();
	}

	runSlick();
}
*/