var $ = require('jquery');
var breakpoint = require("./app-breakpoints");

$(function(){
    $('.accordion').find('.accordion-toggle').click(function(){
        if(!$(this).hasClass('accordion-toggle--open')) {
            $('.accordion').find('.accordion-toggle--open').removeClass("accordion-toggle--open").next().slideToggle('fast');

            $(this).toggleClass("accordion-toggle--open").next().slideToggle('fast', function(){
                if(breakpoint() != "smartphone") {
	                $('html, body').animate({
	                   scrollTop: $(this).offset().top - ( ($('.c-navigation').outerHeight(true) * 2) + ($(this).prev().outerHeight(true) * 2))
	               }, 500);
         		}
            });
        } else {
            $(this).toggleClass("accordion-toggle--open").next().slideToggle('fast');
        }

    });
});

/*
var breakpoint = require("./app-breakpoints");

jQuery( document ).ready(function( $ ) {
    $('.accordion').find('.accordion-toggle').click(function(){

        if(!$(this).hasClass('accordion-toggle--open')) {
            $('.accordion').find('.accordion-toggle--open').removeClass("accordion-toggle--open").next().slideToggle('fast');

            $(this).toggleClass("accordion-toggle--open").next().slideToggle('fast', function(){
                if(breakpoint() != "smartphone") {
	                $('html, body').animate({
	                   scrollTop: $(this).offset().top - ( ($('.navigation').outerHeight(true) * 2) + ($(this).prev().outerHeight(true) * 2))
	               }, 500);
         		}
            });
        } else {
            $(this).toggleClass("accordion-toggle--open").next().slideToggle('fast');
        }

    });
});

 */