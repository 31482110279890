var styles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    }
]
;

window.initialize = function() {
	var pin = [];
	var infowindow =  new google.maps.InfoWindow({
        content: ""
    });
	var bounds = new google.maps.LatLngBounds();
	var zoom = 8;

	var styledMap = new google.maps.StyledMapType(styles, {name: "custom-map"});


	var options = {
        zoom      : zoom,
       // center    : latLng,
        scrollwheel: false,
        mapTypeId : google.maps.MapTypeId.TERRAIN, // Type de carte, différentes valeurs possible HYBRID, ROADMAP, SATELLITE, TERRAIN
        maxZoom   : 20,
        mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
        },
        zoomControl: true
    };

	if($('.pin').length) {
    	$('.pin').each(function(){
			var data = {
	        	"lat": $(this).attr("data-lat"),
	        	"lng": $(this).attr("data-_lng"),
	        	"pin": $(this).attr("data-pin")
    		};

    		pin.push(data);
    	});
    }

    if(pin.length) {
    	var lat = pin[0]['lat'];
    	var latLng = new google.maps.LatLng(pin[0]['lat'], pin[0]['lng']);
    	var marker_pin = pin[0]['pin'];
		options.center = latLng;
	}

	map = new google.maps.Map(document.getElementById('google'), options);
	map.mapTypes.set('map_style', styledMap);
	map.setMapTypeId('map_style');

	if(pin.length) {
		for (var i = 0; i < pin.length; i++) {
			var marker = new google.maps.Marker({
				map: map,
				position: new google.maps.LatLng(pin[i]['lat'], pin[i]['lng']),
				icon: new google.maps.MarkerImage(pin[i]['pin'])
			});
		}
	}
}

function loadScript() {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
      '&callback=initialize&key=AIzaSyD8QupjQlwa8RvnBHFITw436BvRgBgdbN4';
  document.body.appendChild(script);
}

if (document.getElementById("google")) {
    window.onload = loadScript;
}